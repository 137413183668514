// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-tsx": () => import("/Users/milan/Projects/gatsby/src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-category-tsx": () => import("/Users/milan/Projects/gatsby/src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-support-home-tsx": () => import("/Users/milan/Projects/gatsby/src/templates/supportHome.tsx" /* webpackChunkName: "component---src-templates-support-home-tsx" */),
  "component---src-templates-faq-tsx": () => import("/Users/milan/Projects/gatsby/src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-pages-404-js": () => import("/Users/milan/Projects/gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("/Users/milan/Projects/gatsby/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/milan/Projects/gatsby/.cache/data.json")

